window.SUB = window.SUB || {};

$(function() {
	SUB.home.init();
});

SUB.home = {
	init : function(){
		var $carousel = $("#header-carousel");
		<!-- init header carousel -->
		$carousel.imagesLoaded(function(){
			$carousel.owlCarousel({
				items : 1,
				singleItem : true,
				autoPlay : true,
				stopOnHover : true
			});
		});



		<!-- recipe scroller -->
		var controller = new ScrollMagic.Controller({ease: Circ.easeInOut}),
			$scroll_bg = $('.scroll_bg');

		function scrollTracker($element){
			var sceneClass = $element.attr('class');

			BGC.track('animation|nav|animation-scene-complete-' + sceneClass);
		};

		var fast_background = TweenMax.fromTo($scroll_bg,
			1,
            {'background-position': '0px 0px'},
            {'background-position': '-2500px 0px'}
		);

		new ScrollMagic.Scene(
            {
	            duration: '200%',
	            triggerElement: $scroll_bg[0],
	            triggerHook:1.0

	        }).setTween(fast_background).addTo(controller);
	}
};

